import React, { useState } from 'react'
import { Stack, Box, Select, MenuItem, Button, Typography } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { useAtom } from 'jotai'
import { authAtom, staffsAtom } from '../../../../actions'
import { LEAD_ORDER_STATUS, PAYMENT_METHOD, convertMoney, hasPermission } from '../../../../utils/utils'
import dayjs from 'dayjs'

const OrderItem = ({ changeOrderDate, onCancelPayment, onUpdateOrder, orders, payments, order_date, status, id, sale1, sale2, telesale, onChangeSale1, onChangeSale2, onChangeTelesale, onCancelOrder, onAddPayment, onChangeComission }) => {
    const [auth] = useAtom(authAtom)
    const [staffs] = useAtom(staffsAtom)

    let totalPrice = 0

    for (const order of orders) {
        totalPrice += (order.price)
    }

    let totalPaid = 0

    for (const payment of payments) {
        totalPaid += (payment.amount)
    }

    const staffOptions = staffs.map((item) => ({ label: item.full_name, id: item.id }))
    staffOptions.unshift({ label: 'Không có', id: null })

    return (
        <Stack style={{ background: '#eeeeee', padding: '8px', borderRadius: 5 }}>
            <Stack style={{ flex: 1, fontSize: '13px' }}>
                <Stack sx={{ gap: '3px' }}>
                    <Typography sx={{ fontSize: '13px' }}>{`Đơn hàng số: #${id.toString().padStart(6, '0')}`}</Typography>
                    <Typography sx={{ fontSize: '13px' }}>{`Thời gian đặt: ${dayjs(order_date).format('HH:mm DD/MM/YYYY')}`}
                        <span style={{ marginLeft: '10px', color: 'blue', textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => changeOrderDate && changeOrderDate()}>Thay đổi</span>
                    </Typography>
                    <Typography sx={{ fontSize: '13px' }}>{`Trạng thái: ${LEAD_ORDER_STATUS[status.toString()]}`}</Typography>
                </Stack>
                <Stack sx={{ gap: '3px', mt: '3px' }}>
                    <Typography sx={{ fontSize: '13px' }}>{`Tổng giá tiền: ${convertMoney(totalPrice, true)}`}</Typography>
                    <Typography sx={{ fontSize: '13px' }}>{`Đã thanh toán: ${convertMoney(totalPaid, true)}`}</Typography>


                    <Typography sx={{ fontSize: '13px' }}>{`Nhân viên hỗ trợ:`}</Typography>
                    <Stack sx={{ fontSize: '13px', gap: '2px', background: '#dddddd', padding: '8px 8px', margin: '0px 8px', borderRadius: '5px' }}>
                        <Stack direction='row' alignItems='center' spacing='5px'>
                            <Typography sx={{ fontSize: '13px' }}>{`Sale 1:`}</Typography>
                            <Select disabled={!hasPermission(auth, ['write_lead_all'])}
                                sx={{ width: '180px', fontSize: '13px' }} variant='standard'
                                value={sale1 ? sale1.id : null} onChange={(e) => onChangeSale1(e.target.value)}>
                                {staffOptions.map((item) => (
                                    <MenuItem value={item.id}>{item.label}</MenuItem>
                                ))}
                            </Select>
                        </Stack>
                        <Stack direction='row' alignItems='center' spacing='5px'>
                            <Typography sx={{ fontSize: '13px' }}>{`Sale 2:`}</Typography>
                            <Select disabled={!hasPermission(auth, ['write_lead_all'])}
                                sx={{ width: '180px', fontSize: '13px' }} variant='standard'
                                value={sale2 ? sale2.id : null} onChange={(e) => onChangeSale2(e.target.value)}>
                                {staffOptions.map((item) => (
                                    <MenuItem value={item.id}>{item.label}</MenuItem>
                                ))}
                            </Select>
                        </Stack>
                        <Stack direction='row' alignItems='center' spacing='5px'>
                            <Typography sx={{ fontSize: '13px' }}>{`Telesale:`}</Typography>
                            <Select disabled={!hasPermission(auth, ['write_lead_all'])}
                                sx={{ width: '180px', fontSize: '13px' }} variant='standard'
                                value={telesale ? telesale.id : null} onChange={(e) => onChangeTelesale(e.target.value)}>
                                {staffOptions.map((item) => (
                                    <MenuItem value={item.id}>{item.label}</MenuItem>
                                ))}
                            </Select>
                        </Stack>
                    </Stack>


                    <Typography sx={{ fontSize: '13px', marginTop: '8px' }}>{`Thanh toán:`}</Typography>
                    <Stack sx={{ marginTop: '5px' }}>
                        {payments.map((payment, index) => {
                            return (
                                <Stack key={payment.id.toString()} direction='row' style={{ background: '#dddddd', padding: '0px 8px', fontSize: '13px', borderRadius: 5, margin: '0px 8px', alignItems: 'center' }}>
                                    <Stack direction='row' width='100%' alignItems='center'>
                                        <Stack direction='column' flex={1} sx={{ gap: '5px', padding: '8px 0px' }}>
                                            <span>{`Số tiền: ${convertMoney(payment.amount, true)} - ${PAYMENT_METHOD[payment.payment_method]}`}</span>
                                            <span>{`Thời gian: ${dayjs(payment.payment_date).format('HH:mm DD/MM/YYYY')}`}</span>
                                            <span>{`Hoa hồng sale chính: ${convertMoney(payment.comission_sale1, true)}`}</span>
                                            <span>{`Hoa hồng sale phụ: ${convertMoney(payment.comission_sale2, true)}`}</span>
                                            <span>{`Hoa hồng telesale: ${convertMoney(payment.comission_telesale, true)}`}</span>
                                        </Stack>
                                        <Stack direction='column' gap='8px'>
                                            {hasPermission(auth, ['write_comission']) && <Button sx={{ height: '40px' }} onClick={() => onChangeComission && onChangeComission(payment)} variant='contained' color='success'>Thay đổi hoa hồng</Button>}
                                            <Button sx={{ height: '40px' }} onClick={() => onCancelPayment && onCancelPayment(payment)} variant='contained' color='error'>Hủy thanh toán</Button>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            )
                        })}
                    </Stack>
                </Stack>
                <Typography sx={{ fontSize: '13px', marginTop: '8px' }}>{`Các liệu trình:`}</Typography>
                <Stack sx={{ marginTop: '5px' }}>
                    {orders.map((order, index) => {
                        return (
                            <Stack key={order.id.toString()} direction='row' style={{ background: '#dddddd', padding: '0px 8px', fontSize: '13px', borderRadius: 5, margin: '0px 8px', alignItems: 'center' }}>
                                <Box flex={1}>
                                    <p>{`Tên liệu trình: ${order.service_name ?? order.service.name}`}</p>
                                    <p>{`Giá tiền: ${convertMoney(order.price, true)}`}</p>
                                    <p>{`Số buổi: ${order.number_of_booking ?? ''}`}</p>
                                    {order.note && <p>{`Note: ${order.note ?? ''}`}</p>}
                                </Box>
                                <Button sx={{ height: '30px' }} variant='outlined' onClick={() => onUpdateOrder && onUpdateOrder(order)}>Cập nhật</Button>
                            </Stack>
                        )
                    })}
                </Stack>

            </Stack>
            <Stack direction='row' justifyContent='center' spacing='32px' marginTop='16px'>
                {totalPaid === 0 && <Button onClick={() => onCancelOrder && onCancelOrder()} variant='contained' color='error'>Huỷ đơn</Button>}
                {totalPaid < totalPrice && <Button onClick={() => onAddPayment && onAddPayment()} variant='contained' color='success'>Thanh toán</Button>}
            </Stack>
        </Stack>
    )
}

export default OrderItem