// src/LoginPage.js
import { useAtom } from 'jotai';
import React, { useState } from 'react';
import { authAtom, tokenAtom } from '../../../actions';
import { API_BASE_URL } from '../../../actions/api'
import axios from 'axios'
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { hasPermission } from '../../../utils/utils';
import { Box, TextField, Button, Typography, Paper, Container } from '@mui/material';

const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
};

const formStyle = {
    backgroundColor: '#f2f2f2',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    maxWidth: '400px',
    width: '90%',
    textAlign: 'center',
};

const inputStyle = {
    width: '100%',
    padding: '10px',
    margin: '10px 0',
    border: '1px solid #ccc',
    borderRadius: '4px',
};

const buttonStyle = {
    width: '100%',
    padding: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
};

function LoginPage() {
    const history = useHistory()
    const location = useLocation()
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [token, setToken] = useAtom(tokenAtom)
    const [, setAuth] = useAtom(authAtom)

    useState(() => {
        if (token && location && location.state && location.state.from)
            history.push(location.state.from)
    }, [token, location])

    const handleLogin = async () => {
        try {
            const response = await axios.post('/rest-auth/login/', {
                username: username,
                password: password
            })
            console.log(response)
            if (response && response.data && response.data.key) {

                axios.defaults.headers.common['Authorization'] = `Token ${response.data.key}`

                const userRes = await axios.get('/rest-auth/user/')
                if (userRes && userRes.data) {
                    if (!(userRes.data.account_type !== 1 && hasPermission(userRes.data, ['crm_access']))) {
                        toast.error('Tài khoản không được cấp quyền CRM')
                        return
                    }
                    setAuth(userRes.data)
                    setToken(response.data.key)
                    history.push('/crm/report')
                } else {
                    toast.error('Tài khoản không hợp lệ')
                }
            } else {
                toast.error('Đăng nhập không thành công')
            }
        } catch (error) {
            toast.error('Đăng nhập không thành công')
        }
    };

    return (
        <Container
            maxWidth={false}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                background: 'linear-gradient(to bottom, #333333, #000000)',
                padding: 2,
            }}
        >
            <Paper
                elevation={6}
                sx={{
                    padding: { xs: 3, sm: 4, md: 5 },
                    borderRadius: 2,
                    textAlign: 'center',
                    width: '100%',
                    maxWidth: { xs: '95%', sm: '80%', md: '50%', lg: '35%' },
                    margin: 'auto',
                    boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: 'bold',
                        marginBottom: 2,
                        color: '#c83461',
                    }}
                >
                    Anhdior CRM
                </Typography>

                <Box component="form" noValidate autoComplete="off">
                    <TextField
                        fullWidth
                        label="Số điện thoại"
                        variant="outlined"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        sx={{ marginBottom: 2 }}
                    />

                    <TextField
                        fullWidth
                        type="password"
                        label="Mật khẩu"
                        variant="outlined"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{ marginBottom: 2 }}
                    />

                    <Button
                        fullWidth
                        variant="contained"
                        sx={{
                            marginTop: 2,
                            padding: '12px',
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            backgroundColor: '#c83461',
                            '&:hover': {
                                backgroundColor: '#a0294b',
                            },
                        }}
                        onClick={handleLogin}
                    >
                        Đăng nhập
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
}

export default LoginPage;
