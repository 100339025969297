import { toast } from 'react-toastify';
import { Stack, Button, Typography } from '@mui/material'

export const getOrderId = (id) => {
    return `#${id.toString()}` // `#${id.toString().padStart(6, '0')}`
}

export const ACCOUNT_TYPE = {
    '1': 'Khách hàng',
    '2': 'Quản lý',
    '3': 'Tổng quản lý',
    '4': 'Sale',
    '5': 'Telesale',
    '6': 'Kỹ thuật viên',
    '7': 'Kế toán',
    '8': 'Lễ tân',
    '9': 'Y tá',
    '10': 'Phòng răng',
    '11': 'Marketing',
    '12': 'Phòng tóc',
    '13': 'Bác sĩ'
}

export const LEAD_STATUS = {
    '0': 'Chưa có tiền trình',
    '1': 'Telesale chăm sóc',
    '2': 'Khách hàng quan tâm',
    '3': 'Đã đặt lịch tư vấn',
    '4': 'Khách mua dịch vụ'
}

export const LEAD_ORDER_STATUS = {
    '-1': 'Đã huỷ',
    '0': 'Đơn chưa thanh toán đầy đủ',
    '1': 'Đặt thành công',
    '2': 'Đã xác nhận'
}

export const LEAD_ORDER_PAYMENT_STATUS = {
    '1': 'Đơn chưa thanh toán',
    '2': 'Đơn chưa thanh toán đầy đủ',
    '3': 'Thanh toán đầy đủ'
}

export const CUSTOMER_PRIOTIES = {
    '1': 'Thường',
    '2': 'Đồng',
    '3': 'Bạc',
    '4': 'Vàng',
    '5': 'Kim cương',
}

export const ORDER_STATUS = {
    '-1': 'Đã huỷ',
    '1': 'Đặt thành công',
    '2': 'Đang tiến hàng',
    '3': 'Đã hoàn thành'
}

export const PAYMENT_METHOD = {
    '1': 'Ví Anhdior Lotus',
    '2': 'Tiền mặt',
    '3': 'Quà tặng',
    "4": 'Liệu trình cũ',
    '5': 'Chuyển khoản',
    '6': 'Quẹt thẻ',
}

export const BOOKING_STATUS = {
    '-1': 'Đã huỷ',
    '1': 'Đặt thành công',
    '2': 'Xác nhận lịch',
    '3': 'AnhDior đón tiếp tại quầy',
    '4': 'Xác nhận kỹ thuật viên',
    '5': 'Bắt đầu thực hiện',
    '6': 'Thực hiện xong liệu trình',
    '7': 'Hoàn tất'
}

export const convertMoney = (money, showCurrency = false) => {
    if (!money) return money
    return (Math.round(money * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (showCurrency ? '₫' : '')
}

export const isManager = (auth) => {
    if (auth && (auth.account_type === 2 || auth.account_type === 3 || auth.account_type === 7 || auth.account_type === 8))
        return true
    return false
}

export function haveCommonObjects(array1, array2) {
    try {
        return (array1 ?? []).some(obj1 => (array2 ?? []).some(obj2 => obj1 === obj2));
    } catch (error) {
        return false
    }
}

export const hasPermission = (auth, permissions = []) => {
    if (auth && auth.permissions && (haveCommonObjects(auth.permissions ?? [], permissions)))
        return true
    return false
}

export const ConfirmationToast = ({ message, onConfirm, onCancel }) => {
    const handleConfirm = () => {
        onConfirm();
        toast.dismiss();
    };

    const handleCancel = () => {
        onCancel();
        toast.dismiss();
    };

    return (
        <Stack sx={{ gap: '8px', padding: '16px' }}>
            <Typography sx={{ color: 'black' }}>{message}</Typography>

            <Stack direction='row' sx={{ width: '100%', justifyContent: 'space-between' }}>
                <Button onClick={handleCancel}>Huỷ</Button>
                <Button variant='contained' onClick={handleConfirm}>Đồng ý</Button>
            </Stack>
        </Stack>
    );
};

export const showConfirmationToast = (message, onConfirm, onCancel) => {
    toast(<ConfirmationToast message={message} onConfirm={onConfirm} onCancel={onCancel} />, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        closeOnClick: false,
    });
};

export const LEAD_STATUS_LIST = [
    { id: 0, name: 'Chưa có tiến trình' },
    { id: 1, name: 'Khách hàng tiềm năng mức 1' },
    { id: 2, name: 'Khách hàng tiềm năng mức 2' },
    { id: 3, name: 'Booking' },
    { id: 5, name: 'Đến phòng khám' },
    { id: 6, name: 'Đặt cọc' },
    { id: 7, name: 'Full tiền' },
    { id: 8, name: 'Xin lỗi' },
    { id: 9, name: 'Không đến phòng khám' },
    { id: 10, name: 'BONG' },
]

export const LEAD_STATUS_LIST_MAP = {
    0: 'Chưa có tiến trình',
    1: 'Khách hàng tiềm năng mức 1',
    2: 'Khách hàng tiềm năng mức 2',
    3: 'Booking',
    5: 'Đến phòng khám',
    6: 'Đặt cọc',
    7: 'Full tiền',
    8: 'Xin lỗi',
    9: 'Không đến phòng khám',
    10: 'BONG',
}

export const PERMISSION_DICT = {
    'crm_access': 'Truy cập CRM',
    'management_access': 'Quản trị tài khoản',
    'view_report': 'Báo cáo cá nhân',
    'view_report_all': 'Tất cả báo cáo',
    'export_report_all': 'Xuất báo cáo chung',
    'create_new_lead': 'Tạo Lead mới',
    'create_exist_lead': 'Tạo lead khách cũ',
    'view_lead': 'Xem Lead cá nhân',
    'view_lead_all': 'Xem tất cả Lead',
    'write_lead_all': 'Sửa tất cả Lead',
    'assign_lead': 'Có thể gắn Lead sale',
    'view_group': 'Xem nhóm',
    'view_group_all': 'Xem tất cả nhóm',
    'view_customer': 'Xem khách hàng',
    'view_customer_all': 'Xem tất cả khách hàng',
    'export_report_customer': 'Xuất báo cáo khách hàng',
    'view_order': 'Xem đơn hàng',
    'view_order_all': 'Xem tất cả đơn hàng',
    'export_report_order': 'Xuất báo cáo đơn hàng',
    'view_payment': 'Xem thanh toán',
    'view_payment_all': 'Xem tất cả thanh toán',
    'write_payment': 'Xác nhận thanh toán',
    'export_report_payment': 'Xuất báo cáo thanh toán',
    'view_comission': 'Xem hoa hồng',
    'view_comission_all': 'Xem tất cả hoa hồng',
    'write_comission': 'Phê duyệt hoa hồng',
    'export_report_comission': 'Xuất báo cáo hoa hồng',
    'write_lead': 'Có thể cập nhật lead được gắn',
    'write_lead_sale': 'Có thể sửa sale/telesale của lead',
    'create_group': 'Có thể thêm nhóm KH',
    'write_group': 'Có thể sửa nhóm KH',
    'write_group_all': 'Có thể sửa/thêm tất cả nhóm khách hàng',
    'create_customer': 'Có thể tạo khách hàng mới',
    'write_customer': 'Có thể sửa khách hàng được gắn',
    'write_customer_all': 'Có thể sửa tất cả khách hàng'
}

export const ALL_PERMISSIONS = [
    {
        title: 'Quyền truy cập',
        items: [
            { id: 1, title: 'Truy cập CRM', parent: null, permission: 'crm_access' },
            { id: 21, title: 'Quyền quản trị, truy cập chức năng quản lý', parent: 1, permission: 'management_access' },
        ]
    },
    {
        title: 'Báo cáo',
        items: [
            { id: 2, title: 'Xem báo cáo của cá nhân', parent: 1, permission: 'view_report' },
            { id: 3, title: 'Xem báo cáo tất cả nhân viên', parent: 1, permission: 'view_report_all' },
            { id: 24, title: 'Xuất báo cáo chung', parent: 1, permission: 'export_report_all' },
        ]
    },
    {
        title: 'Quản lý Lead',
        items: [
            { id: 4, title: 'Tạo lead từ khách hàng mới', parent: 1, permission: 'create_new_lead' },
            // { id: 5, title: 'Tạo lead từ khách hàng cũ đã được gắn', parent: 1, permission: 'create_exist_lead' },
            { id: 6, title: 'Xem danh sách lead được gắn', parent: 1, permission: 'view_lead' },
            { id: 29, title: 'Có thể sửa lead được gắn', parent: 1, permission: 'write_lead' },
            { id: 7, title: 'Xem danh sách tất cả lead', parent: 1, permission: 'view_lead_all' },
            { id: 30, title: 'Có thể sửa tất cả các lead', parent: 1, permission: 'write_lead_all' },
            { id: 8, title: 'Có quyền thay đổi telesale/sale của lead', parent: 1, permission: 'write_lead_sale' },
            { id: 23, title: 'Hệ thống có thể gán Lead cho sale đó khi telesale chuyển trạng thái', parent: 1, permission: 'assign_lead' },
        ]
    },
    {
        title: 'Nhóm khách hàng',
        items: [
            { id: 9, title: 'Xem được nguồn khách', parent: 1, permission: 'view_group' },
            { id: 36, title: 'Tạo mới được nguồn khách', parent: 1, permission: 'create_group' },
            { id: 31, title: 'Sửa được nguồn khách', parent: 1, permission: 'write_group' },
            { id: 10, title: 'Xem tất cả nguồn khách của mọi người', parent: 1, permission: 'view_group_all' },
            { id: 32, title: 'Sửa/xoá được tất cả nguồn khách của mọi người', parent: 1, permission: 'write_group_all' },
        ]
    },
    {
        title: 'Khách hàng',
        items: [
            { id: 11, title: 'Xem được khách hàng được gắn', parent: 1, permission: 'view_customer' },
            { id: 12, title: 'Xem được tất cả khách hàng', parent: 1, permission: 'view_customer_all' },
            { id: 25, title: 'Xuất báo cáo khách hàng', parent: 1, permission: 'export_report_customer' },
            { id: 33, title: 'Có quyền tạo mới khách hàng', parent: 1, permission: 'create_customer' },
            { id: 34, title: 'Có thể cập nhật khách hàng được gắn', parent: 1, permission: 'write_customer' },
            { id: 35, title: 'Có thể cập nhật tất cả khách hàng', parent: 1, permission: 'write_customer_all' },
        ]
    },
    {
        title: 'Đơn hàng',
        items: [
            { id: 13, title: 'Xem đơn hàng tạo bởi tài khoản đó', parent: 1, permission: 'view_order' },
            { id: 14, title: 'Xem được tất cả đơn hàng', parent: 1, permission: 'view_order_all' },
            { id: 26, title: 'Xuất báo cáo đơn hàng', parent: 1, permission: 'export_report_order' },
        ]
    },
    {
        title: 'Thanh toán',
        items: [
            { id: 15, title: 'Xem được thanh toán tạo bởi tài khoản đó', parent: 1, permission: 'view_payment' },
            { id: 16, title: 'Xem được tất cả thanh toán', parent: 1, permission: 'view_payment_all' },
            { id: 17, title: 'Xác nhận được thanh toán', parent: 1, permission: 'write_payment' },
            { id: 27, title: 'Xuất báo cáo thanh toán', parent: 1, permission: 'export_report_payment' },
            { id: 37, title: 'Có thể thay đổi đơn hàng của thanh toán', parent: 1, permission: 'change_payment_order' },
        ]
    },
    {
        title: 'Hoa hồng',
        items: [
            { id: 18, title: 'Xem được hoa hồng của tài khoản đó', parent: 1, permission: 'view_comission' },
            { id: 19, title: 'Xem được tất cả hoa hồng', parent: 1, permission: 'view_comission_all' },
            { id: 20, title: 'Có quyền chia hoa hồng', parent: 1, permission: 'write_comission' },
            { id: 28, title: 'Xuất báo cáo hoa hồng', parent: 1, permission: 'export_report_comission' },
        ]
    },
]