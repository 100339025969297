import React, { useState } from 'react'
import { Box, Tooltip, Drawer, IconButton, MenuItem, MenuList, Stack, Typography } from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'
import { useAtom, useAtomValue } from 'jotai';
import { authAtom, staffsAtom, tokenAtom } from '../actions';
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'

const logo_image = require('../assets/images/logo_icon.png')

const menu = {
    'crm': {
        title: 'Sale',
        permissions: ['crm_access'],
        items: [
            { key: '1', name: 'Báo cáo', path: '/crm/report', permissions: ['view_report', 'view_report_all', 'export_report_all'] },
            { key: '2', name: 'Lead', path: '/crm/leads', permissions: ['create_new_lead', 'create_exist_lead', 'view_lead', 'view_lead_all', 'write_lead_all'] },
            // { key: '3', name: 'Deal', path: '/crm/deals' },
            { key: '4', name: 'Nguồn khách', path: '/crm/groups', permissions: ['view_group', 'view_group_all'] },
            { key: '5', name: 'Khách hàng', path: '/crm/contacts', permissions: ['view_customer', 'view_customer_all', 'export_report_customer'] },
            { key: '6', name: 'Đơn hàng', path: '/crm/orders', permissions: ['view_order', 'view_order_all', 'export_report_order'] },
            // { key: '6', name: 'Báo giá', path: '/crm/quotes' },
            { key: '7', name: 'Thanh toán', path: '/crm/payments', permissions: ['view_payment', 'view_payment_all', 'write_payment', 'export_report_payment'] },
            { key: '8', name: 'Hoa hồng', path: '/crm/comissions', permissions: ['view_comission', 'view_comission_all', 'write_comission', 'export_report_comission'] },
            // { key: '8', name: 'Công việc', path: '/crm/tasks' },
        ]
    },
    // 'marketing': [
    //     { key: '9', name: 'Chiến dịch', path: '/marketing/campaigns' },
    //     { key: '10', name: 'Mã giảm giá', path: '/marketing/vouchers' },
    //     { key: '11', name: 'SMS', path: '/marketing/sms-history' }
    // ],
    // 'app': [
    //     { key: '12', name: 'Dịch vụ', path: '/app/services' },
    //     { key: '13', name: 'Nhân viên', path: '/app/staffs' },
    // ],
    'management': {
        title: 'Quản lý',
        permissions: ['management_access'],
        items: [
            // { key: '14', name: 'Báo cáo', path: '/management/reports' },
            { key: '15', name: 'Nhân viên', path: '/management/accounts', permissions: ['management_access'] },
            { key: '16', name: 'Phân quyền', path: '/management/permissions', permissions: ['management_access'] },
        ]
    },
}

function haveCommonObjects(array1, array2) {
    // Check if any object in array1 is present in array2
    return array1.some(obj1 => array2.some(obj2 => obj1 === obj2));
}

const SideBar = () => {
    const location = useLocation()
    const history = useHistory()
    const [auth, setAuth] = useAtom(authAtom)
    const [token, setToken] = useAtom(tokenAtom)
    const [isMobileOpen, setMobileOpen] = useState(false);

    const toggleMobileDrawer = () => {
        setMobileOpen((prev) => !prev);
    };

    if (location.pathname.indexOf(`/login`) >= 0) return null


    const handleLogout = () => {
        setToken(undefined)
        setAuth(undefined)
    }

    const renderMenuItems = () =>
        Object.keys(menu).map((key) => {
            const section = menu[key]
            if (haveCommonObjects(auth.permissions ?? [], section.permissions ?? [])) {
                return (
                    <Box >
                        <Stack style={{ height: 30, width: '100%', justifyContent: 'center' }}>
                            <Typography style={{ fontSize: 16, fontWeight: 'bold', marginLeft: '8px', color: '#333333', }}>{section.title}</Typography>
                        </Stack>
                        <MenuList>
                            {
                                section.items.map((item) => {
                                    if (haveCommonObjects(auth.permissions ?? [], item.permissions ?? [])) {
                                        return (
                                            <MenuItem
                                                onClick={() => {
                                                    toggleMobileDrawer()
                                                    history.push(item.path)
                                                }}
                                                sx={{
                                                    height: 30, width: '100%', display: 'flex', alignItems: 'center',
                                                    backgroundColor: location.pathname.indexOf(item.path) >= 0 ? '#ff000099' : 'transparent',
                                                    color: location.pathname.indexOf(item.path) >= 0 ? 'white' : 'black',
                                                    textAlign: 'left', justifyContent: 'flex-start',
                                                    fontSize: 14
                                                }}
                                                key={item.key}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        )
                                    } else {
                                        return null
                                    }

                                })
                            }
                        </MenuList>
                    </Box>
                )
            } else {
                return null
            }

        })

    return (
        <Box sx={{ overflow: 'hidden', width: '100%', display: 'flex' }}>
            <IconButton
                sx={{
                    display: { xs: 'block', md: 'none' },
                    position: 'absolute',
                    top: 0,
                    left: 16,
                    zIndex: 150
                }}
                color='success'
                onClick={toggleMobileDrawer}
            >
                <MenuIcon />
            </IconButton>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, borderRight: '1px solid #aaaaaa', width: '100%', height: 'calc(100vh - 60px)', alignItems: 'center', flexDirection: 'column', backgroundColor: '#ffd1dc' }}>
                <Stack sx={{ flex: 1, height: '100vh', overflowY: 'auto', width: '100%' }}>
                    {renderMenuItems()}
                </Stack>
                <Stack flexDirection='row' sx={{ borderRadius: '5px', background: '#eeeeee', width: '80%', padding: '10px 8px' }}>
                    <Stack flex={1}>
                        <Typography sx={{ fontSize: '12px', marginBottom: '3px', whiteSpace: 'pre-line', }}>
                            {`${auth.full_name}`}
                        </Typography>
                        <Typography sx={{ fontSize: '12px', marginBottom: '3px', whiteSpace: 'pre-line', }}>{auth.username}</Typography>
                    </Stack>
                    <Tooltip title="Đăng xuất">
                        <IconButton
                            onClick={handleLogout}
                            sx={{
                                color: 'red',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                },
                            }}
                        >
                            <PowerSettingsNewIcon fontSize='medium' />
                        </IconButton>
                    </Tooltip>
                </Stack>
                <Typography sx={{ fontSize: 10, margin: '8px 0px', textAlign: 'center', width: '100%' }}>{`Version 1.0.4`}</Typography>
            </Box>

            <Drawer
                anchor="left"
                open={isMobileOpen}
                onClose={toggleMobileDrawer}
                sx={{ display: { xs: 'block', md: 'none' } }}
            >
                <Box
                    sx={{
                        width: 240,
                        height: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: '#ffd1dc',
                        overflowY: 'scroll'
                    }}
                >
                    <IconButton
                        sx={{ alignSelf: 'flex-end', margin: 1 }}
                        onClick={toggleMobileDrawer}
                    >
                        <CloseIcon />
                    </IconButton>
                    <img src={logo_image} style={{ width: 80, height: 40, marginTop: 16, marginBottom: 16 }} alt="" />
                    <Stack sx={{ flex: 1, width: '100%' }}>{renderMenuItems()}</Stack>
                    <Stack alignSelf='center' flexDirection='row' sx={{ borderRadius: '5px', background: '#eeeeee', width: '80%', padding: '10px 8px' }}>
                        <Stack flex={1}>
                            <Typography sx={{ fontSize: '12px', marginBottom: '3px', whiteSpace: 'pre-line', }}>
                                {`${auth.full_name}`}
                            </Typography>
                            <Typography sx={{ fontSize: '12px', marginBottom: '3px', whiteSpace: 'pre-line', }}>{auth.username}</Typography>
                        </Stack>
                        <Tooltip title="Đăng xuất">
                            <IconButton
                                onClick={handleLogout}
                                sx={{
                                    color: 'red',
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                    },
                                }}
                            >
                                <PowerSettingsNewIcon fontSize='medium' />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    <Typography sx={{ fontSize: 10, marginBottom: '8px', textAlign: 'center', width: '100%' }}>{`Version 1.0.4`}</Typography>
                </Box>
            </Drawer>
        </Box>
    )
}

export default SideBar